import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import { Controller, Scene } from "react-scrollmagic"
import Particles from "react-particles-js"

const HomeBanner = () => {
  const bannerQueryData = useStaticQuery(graphql`
    query BannerDefaultQueryParticles {
      homepageJson(id: { eq: "home-banner" }) {
        title
        subtitle
      }
      file(relativePath: { eq: "images/home/home-1.webp" }) {
        childImageSharp {
          fixed(quality: 100, width: 1250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const particleOpt = {
    particles: {
      color: {
        value: "#888",
      },
      number: {
        value: 50,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      size: {
        value: 5,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false,
        },
      },
      opacity: {
        value: 0.5,
        random: false,
        anim: {
          enable: false,
          speed: 3,
          opacity_min: 0.1,
          sync: false,
        },
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: "#888",
        opacity: 0.4,
        width: 1,
      },
      shape: {
        type: "circle",
        stroke: {
          width: 0,
          color: "#888",
        },
        polygon: {
          nb_sides: 5,
        },
        image: {
          src: "img/github.svg",
          width: 100,
          height: 100,
        },
      },
      move: {
        enable: true,
        speed: 2,
        direction: "none",
        random: false,
        straight: false,
        out_mode: "out",
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
    },
    fpsLimit: 60,
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "repulse",
        },
      },
    },
  }

  const bannerImage = bannerQueryData.file.childImageSharp.fixed
  const title = bannerQueryData.homepageJson.title
  const subtitle = bannerQueryData.homepageJson.subtitle

  return (
    <div className="rn-slider-area section-hero bottomGap" id="home">
      <div className="rn-slide slider-style banner-fixed-height">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-8">
              <div className="inner">
                <div className="content">
                  <h1
                    className="title wow fadeInLeft"
                    data-wow-delay="200ms"
                    data-wow-duration="1000ms"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></h1>
                  <h4
                    className="subtitle wow fadeInLeft"
                    data-wow-delay="200ms"
                    data-wow-duration="1000ms"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                  ></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="thumbnail">
        <div className="trigger" id="trigger" />
        <Controller>
          <Scene
            classToggle="animated"
            triggerElement="#trigger"
            triggerHook="onCenter"
          >
            <div className="thumbnail-inner rn_surface story-image">
              <Img className="banner-images" fixed={bannerImage} />
            </div>
          </Scene>
        </Controller>
        <Particles className="particle" params={particleOpt} />
      </div>
    </div>
  )
}

export default HomeBanner
