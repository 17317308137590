import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Controller, Scene } from "react-scrollmagic"

const AboutBlock = () => {
  const aboutQueryData = useStaticQuery(graphql`
    query AboutQueryData {
      homepageJson(id: { eq: "home-about" }) {
        title
        subtitle
        description
        description1
      }
      file(relativePath: { eq: "images/home/home-2.webp" }) {
        childImageSharp {
          fixed(quality: 100, width: 518, height: 480) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const title = aboutQueryData.homepageJson.title
  const subtitle = aboutQueryData.homepageJson.subtitle
  const description = aboutQueryData.homepageJson.description
  const description1 = aboutQueryData.homepageJson.description1
  const image = aboutQueryData.file.childImageSharp.fixed

  return (
    <div className="about-me bottomGap" id="about">
      <div className="container sm:p-8 lg:p-6 mx-auto md:mx-md lg:mx-2xl">
        <div className="row row--45 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <div className="trigger" id="trigger2" />
              <Controller>
                <Scene
                  classToggle="animated"
                  triggerElement="#trigger2"
                  triggerHook="onCenter"
                >
                  <div className="rn_surface story-image">
                    <Img className="about-images" fixed={image} />
                  </div>
                </Scene>
              </Controller>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="section-wrapper">
              <div className="section-title with-border">
                <h3
                  className="title wow fadeInLeft"
                  data-wow-delay="200ms"
                  data-wow-duration="1000ms"
                >
                  {title}
                  <span className="bg">About</span>
                </h3>
                {title && (
                  <h4
                    className="subtitle wow fadeInLeft"
                    data-wow-delay="200ms"
                    data-wow-duration="1000ms"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                  ></h4>
                )}
              </div>
              {description && (
                <p
                  className="description wow fadeInLeft"
                  data-wow-delay="200ms"
                  data-wow-duration="1000ms"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></p>
              )}
              {description1 && (
                <p
                  className="description wow fadeInLeft"
                  data-wow-delay="200ms"
                  data-wow-duration="1000ms"
                  dangerouslySetInnerHTML={{ __html: description1 }}
                ></p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutBlock
