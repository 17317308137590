import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ContactForm from "../contactform"

const HomeContact = () => {
  const contactData = useStaticQuery(graphql`
    query contactDataQuery {
      site {
        siteMetadata {
          getform_url
        }
      }
    }
  `)

  const {
    site: {
      siteMetadata: { getform_url },
    },
  } = contactData

  return (
    <section className="home-contact bottomGap" id="contact">
      <div className="container">
        <div className="row bottomGap">
          <div className="contact-wrapper">
            <div className="section-title">
              <h2 className="title">
                Get in touch
                <span className="bg">Contact</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="contact-form-wrapper wow fadeInLeft">
            <div className="info">
              <p>
                If you want to get in touch use the form below and I will get
                back to you as soon as possible.
              </p>
            </div>
            <ContactForm url={getform_url} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeContact
