import React from "react"
import Layout from "../components/layout"
import AboutBlock from "../components/section/aboutBlock"
import HomeBanner from "../components/section/homeBanner"
import HomeContact from "../components/section/homeContact"
import HomeServices from "../components/section/homeServices"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Cloud Consultant & Architect" />
    <HomeBanner />
    <AboutBlock />
    <HomeServices />
    <HomeContact />
  </Layout>
)

export default IndexPage
