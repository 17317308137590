import React from "react"
import {
  AiOutlineCloudServer,
  AiOutlineShoppingCart,
  AiOutlineCloudSync,
} from "react-icons/ai"

const HomeServices = () => {
  return (
    <section className="home-services bottomGap" id="services">
      <div className="services-area">
        <div className="container">
          <div className="row">
            <div className="service-container">
              <div
                className="service wow fadeInLeft"
                data-wow-delay="200ms"
                data-wow-duration="1000ms"
              >
                <div className="inner">
                  <div className="icon">
                    <AiOutlineCloudServer />
                  </div>
                  <div className="content">
                    <h4 className="title">Cloud Architecture</h4>
                    <p>
                      With my experience, I can advise you how to build your
                      infrastructure to be scalable, secure and cost effective.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-container">
              <div
                className="service wow fadeInLeft"
                data-wow-delay="200ms"
                data-wow-duration="1000ms"
              >
                <div className="inner">
                  <div className="icon">
                    <AiOutlineCloudSync />
                  </div>
                  <div className="content">
                    <h4 className="title">Serverless Consultancy</h4>
                    <p>
                      Serverless technology is the future of the web. From small
                      websites to complex workflows, is always a pleasure to
                      build them from the ground up.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-container">
              <div
                className="service wow fadeInLeft"
                data-wow-delay="200ms"
                data-wow-duration="1000ms"
              >
                <div className="inner">
                  <div className="icon">
                    <AiOutlineShoppingCart />
                  </div>
                  <div className="content">
                    <h4 className="title">eCommerce Consultancy</h4>
                    <p>
                      The commerce is moving online. With years of experience
                      behind me, I'm sure I can advise you on how to take your
                      eCommerce site to the next level.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeServices
