import React, { useState } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"

const ContactForm = ({ url }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const [value, setValue] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
      setValue({
        name: "",
        email: "",
        subject: "",
        message: "",
      })
    }
  }
  const onSubmit = (data, e) => {
    const form = e.target
    setServerState({ submiting: true })
    axios({
      method: "post",
      url: url,
      data,
    })
      .then(res => {
        handleServerResponse(true, "Thanks for your email!", form)
      })
      .catch(err => {
        handleServerResponse(false, err.response.data.error, form)
      })
  }
  const isErrors = Object.keys(errors).length !== 0 && true
  const onChangeHandler = e => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
      <div
        className={`form-group ${isErrors && errors.name ? "has-error" : ""} ${
          value.name ? "has-value" : ""
        }`}
      >
        <input
          type="text"
          name="name"
          id="name"
          onChange={onChangeHandler}
          {...register("name", {
            required: true,
            minLength: {
              value: 5,
              message: "Your full name is required",
            },
          })}
        />
        <label htmlFor="name">Full Name</label>
        {errors.name && <span className="error">{errors.name.message}</span>}
      </div>
      <div
        className={`form-group ${isErrors && errors.email ? "has-error" : ""} ${
          value.email ? "has-value" : ""
        }`}
      >
        <input
          type="text"
          name="email"
          id="email"
          onChange={onChangeHandler}
          {...register("email", {
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format",
            },
          })}
        />
        <label htmlFor="email">Your email</label>
        {errors.email && <span className="error">{errors.email.message}</span>}
      </div>
      <div className="form-group">
        <input
          type="text"
          name="subject"
          id="subject"
          onChange={onChangeHandler}
          {...register("subject", {
            required: true,
            minLength: {
              value: 5,
              message: "The subject should have at least 6 characters.",
            },
          })}
        />
        <label htmlFor="subject">Subject</label>
        {errors.subject && (
          <span className="error">{errors.subject.message}</span>
        )}
      </div>
      <div className="form-group">
        <textarea
          name="message"
          id="message"
          onChange={onChangeHandler}
          {...register("message", {
            required: true,
            minLength: {
              value: 10,
              message: "The message should be at least 10 characters long.",
            },
          })}
        ></textarea>
        <label htmlFor="message">Your Message</label>
        {errors.message && (
          <span className="error">{errors.message.message}</span>
        )}
      </div>
      <div className="form-submit">
        <button
          className="btn-primary"
          type="submit"
          disabled={serverState.submitting}
        >
          Send message
        </button>
      </div>
    </form>
  )
}
export default ContactForm
